body {
  font-family: "poppins";
  margin: 0;
  font-size: 13px;
}

button {
  border: none;
}

.splash{
  background-image: url(./images/splash.png);
  background-size: cover;
  background-attachment: fixed;
  max-width: 500px;
  margin: 0 auto;
  height: 100vh;
  padding-top:25%;
}
.screen {
  position: relative;
  margin: 0 auto;
  height: 100vh;
  background: #ececec;
}
.back-arrow {
  height: 20%;
  width: auto;
  position: absolute;
  top: 8%;
  left: 15px;
}
.next-arrow {
  height: 20%;
      width: auto;
      position: absolute;
      top: 8%;
      right: 15px;
}
.back-arrow img, .next-arrow img {
  height: 100%;
  width: auto;
}
.logo {
  width: 50%;
  margin: 0 auto;
}
.image30 {
  width: 30%;
  margin: 0 auto;
}
.image15 {
  width: 15%;
  margin: 0 auto;
}
.logo-blue {
  width: 50%;
  margin: 0 auto;
}
.responsive {
  width: 100%;
  height: auto;
}
.go-left {
  float: left;
}
.topbar {
  height: 7.5%;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
  padding: 20px 0;
  background: #c52f2f;
}
.left-text {
  float: left;
      text-align: left;
      width: 53%;
      line-height: 1.5;
      color: #777;
      padding-left: 7%;
}
.left-text2 {
  float: left;
      text-align: left;
      width:83%;
      line-height: 1.5;
      color: #222;
      font-weight: 500;
      padding-left: 7%;
}
.split {
  width: 86%;
  float: left;
  border-bottom: 1px solid #ccc;
  margin-top: 5px;
  margin-bottom:5px;
  height: 1px;
  margin-left: 7%;
}

.btn-invert {
    border: 1px solid #1d8cbb;
    background-color: #fff;
    color: #1d8cbb;
}
.btn-green {
    background-color: #37ce00;
    color: #fff;
}
.border-line {
  border-bottom:1px solid #e6e6e6;
  margin: 10px 7%;
  float: left;
  width: 86%;
}
.right-text {
  float: left;
  text-align: right;
  width: 33%;
  color: #222;
  padding-right: 7%;
  font-weight: 700;
}
.progress {
  width: 100%;
float: left;
display: flex;
align-items: center;
justify-content: center;
}
.progress img {
  width: 20%;
  margin-top: 10px;
}
.stage-bar {
  width: 10px;
      margin: 0 2px;
      background-color: #fff;
      opacity: 0.26;
      height: 10px;
      border-radius: 5px;

}
.active-bar {
  opacity: 1;
}
.stage-hero {
  width: 100%;
  float: left;
  height: auto;
  background: #fff;
}
.white-back {
  background-color: #fff!important;
}
.start-hero {
  background-image:url(images/start-hero.png);
  background-size: cover;
}
.hero-90 {
  width: 100%;
  float: left;
  top: 7.5%;
  bottom: 7.5%;
  position: absolute;
  overflow: scroll;
  left: 0;
  right: 0;
}
.hero-95 {
  width: 100%;
  float: left;
  top: 7.5%;
  bottom: 0;
  position: absolute;
  overflow: scroll;
  left: 0;
  right: 0;
}
.bottom-spacer {
  height: 50px;
  float: left;
  width: 100%;
}
.step1-bg {
  background-size: cover;
  background: #efe6e6;
}
.step2-bg {
  background-image:url(images/step2-bg.png);
  background-size: cover;
  background: rgb(71,134,251);
}
.step3-bg {
  background-image:url(images/step3-bg.png);
  background-size: cover;
  background: rgb(71,134,251);
}
.hero-text {
  width: calc(100% - 60px);
  float: left;
  padding:3% 30px;
  line-height: 1.4;
  text-align: center;
  color: #666;
}
.blue-background {
  background-color: #efe6e6;
}
.bottom-zero {
  margin-bottom: 0;
  padding-bottom: 0;
}
.full-width {
  width:100%;
  float: left;
}
.btn {
  width: 88%;
  margin-left: 6%;
  padding: 13px 0;
  text-transform: uppercase;
  float: left;
  text-align: center;
  text-decoration: none;
  font-weight: 300;
  font-family: "poppins";
  border-radius: 30px;
}
.billboard {
  padding: 10px 0 18px 0;
    background: #f5c5c5;
    border: 1px solid #f59f9f;
    border-radius: 8px;
    width: calc(94% - 2px);
    margin-left: 3%;
margin-bottom: 2%;
}
.bill-text{
  color: #a53030;
      text-align: center;
      padding: 0 15px 15px;
      font-size: 12px;
}
.thanks-btn {
  width: 80%!important;
  margin-left: 10%!important;
  margin-top: 20px;
}
.btn-blue {
  background-color: #872626;
  color: #fff;
}
.btn-red {
  background-color: #872626;
  color: #fff;
}
.btn-white {
  background: rgb(255 255 255 / 65%);
    color: rgb(71,134,251);
}
.white-font {
  color: #fff;
}
.top15 {
  margin-top: 15px
}
.top30 {
  margin-top: 30%;
}
.top15-p {
  margin-top: 15%;
}
.top9-p {
  margin-top: 9%;
}
.top15-pm {
  padding-top: 15%;
}
.top10-pm {
  padding-top: 5%;
}
.bottom10-p {
  margin-bottom: 10%;
}
.blue-back {
  background: rgb(71,134,251);
}
.bottom10-pm {
  padding-bottom: 10%;
}
.bottom06-p {
  margin-bottom: 6%;
}
.top60-p {
  margin-top: 60%;
}
.hero-white {
  color: #fff;
  text-align: center;
  float: left;
  width: 100%;
}
.hero-img {
  width: 30%;
      margin-left: 35%;
      float: left;
      margin-top: 22%;
}
.intro-img {
  width: 70%;
      margin-left: 15%;
      float: left;
      margin-top: 22%;
}
.intro-img2 {
  width: 70%;
      margin-left: 15%;
      float: left;
      margin-top: 7%;
}
.home-img {
  width: 65%;
      margin-left: 16%;
      float: left;
      margin-top: 2%;
}
.boldie {
    font-weight: 900;
    color: #555;
    Color: #862726;

}
.add-new {
  position: absolute;
  right: 25px;
  bottom: 13%;
  width: 15%;
  height: auto;
  z-index: 30;
}
.title {
  color: #fff;
  width: 100%;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
}

.back-arrow {
    height: 20%;
    width: auto;
    position: absolute;
    top: 8%;
    left: 15px;
}
.geXTrK {
    height: 7.5%;
    width: 100%;
    float: left;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    background: #c52f2f;
    border-bottom: 1px solid rgb(255 255 255 / 15%);
    z-index: 5;
    left: 0;
    right: 0;
}
.gray{
  background: rgb(71,134,251);
}
.textbox {
  background: #ffffff;
  border-bottom: 1px solid #dee8fb;
  width: 75%;
  margin-left: 10%;
  border-top: 0;
  padding: 10px 2.5%;
  font-family: "poppins";
  border-left: none;
  border-right: none;
  font-size: 13px;
  text-transform: none!important;
  text-align: center;

}
.selector {
    background: #dfe7f7;
    width: 80%;
    border-bottom: 0px solid #fff;
    margin-left: 10%;
    border-top: 0;
    padding: 10px 2.5%;
    font-family: "poppins";
    border-left: none;
    border-right: none;
    font-size: 13px;
}
::placeholder { /* Firefox 18- */
  color: #d9a7a7;
  font-weight: 300;

}
.text-center {
  text-align: center;
}
.home-icon {
  width: calc(90% - 5px);
  padding: 2%;
  margin-left: 3%;
  float: left;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: -0px 1px 6px 0px rgba(0,0,0,0.18);
position: relative;
display: flex;
    align-items: center;
}
.alert-good {
  border-left: 4px solid #287628;
}
.alert-caution {
  border-left: 4px solid orange;
}
.alert-bad {
  border-left: 4px solid red;
}
.alert-neutral {
border-left: 4px solid  #fff;
}
.color-good {
  color: #13bb13;
}
.color-neutral {
  color: #666;
}
.color-caution {
  color: orange;
}
.color-bad {
  color: red;
}
.icon-house {
  width: 15%;
  border: 1px solid rgb(255 255 255 / 1%);;
  float: left;
  border-radius: 4px;
  display: flex;
    align-items: center;

}
.home-item {
  width: 64%;
  padding:0% 1% 0 3%;
  float: left;
}
.home-item2 {
  width: 49%;
  padding:0% 1% 0 3%;
  float: left;
}
.insured-title {
    width: 100%;
    float: left;
    font-size: 12px;
    margin-top: 0px!important;
    line-height: 1.35;
    color: #666;
}
.insured-status {
  width: 100%;
  float: left;
  font-size: 11px;
  margin-top: -2px;
}
.bolda {
  font-weight: 700;
  color:#555;
  text-transform: uppercase;
}
.price {
  position:absolute;
  top:0;
  bottom: 0;
  right: 17%;
  text-align: right;
  font-size: 15px;
  font-weight: 700;
  color: #333;
  width: auto;
  display: flex;
  align-items: center;
}
.icon {
  width: 90%;
  margin-left: 5%;
}
.icon img {
  opacity: 0.2
}
.unread {
  opacity: 1!important;
}
.width-15pad {
  width: calc(100% - 30px);
  padding: 0 15px;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.claim-title {
  width: 94%;
  margin-left: 3%;
  text-align: left;
  font-weight: 600;
  margin-top: -7px;
  float: left;
  padding-bottom: 5px;
}
.adjust-title {
    width: 94%;
    margin-left: 2%;
    text-align: left;
    font-weight: 600;
    margin-top: 5px;
    float: left;
    margin-bottom: -5px!important;
}
.btn-green{
  background: #872626;
  color: #fff;
}
.map-img {
  width: calc(94% - 2px);
    margin-left: 3%;
    float: left;
    margin-bottom: 20px;
        border: 1px solid #cccccc;
}
.blue-widget {
  background: #5ab355;
}
.white-txt {
  color: #fff;
}
.btn-gps {
  position:absolute;
  right: 5px;
  top:  calc(2% + 15px);
  right:  calc(5% + 3px);
  background: #4786fb;
  color: #fff;
  padding: 5px 7px;
  width: auto;
  border-radius: 5px;
}
.footer {
  height: 7.5%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}
.fill-icon {
    width: calc(90% - 5px);
    padding: 2%;
    margin-left: 3%;
    float: left;
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 1px 6px 0px rgb(0 0 0 / 18%);
    position: relative;
}
.go-right {
  position: absolute;
  right: 3%;
}
.fill-box {
  background: #ffffff;
  border: 1px solid #dee8fb;
  width: calc(90% - 2px);
  padding: 10px 3%;
  font-family: "poppins";
  font-size: 13px;
  text-transform: none!important;
  text-align: left;
  margin: 7px 2%;
  float: left;
}
.footer-icon {
width: 33.33%;
float: left;
height: 100%;
display: flex;
align-items: center;
}
.footer-link {
  height: 75%;
  margin-left: 33%;
}
#rangeValue {
  position: relative;
  display: block;
  text-align: center;
  font-size: 6em;
  color: #999;
  font-weight: 400;
}
.range {
  width: 400px;
  height: 15px;
  -webkit-appearance: none;
  background: #111;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #00fd0a;
  cursor: pointer;
  border: 4px solid #333;
  box-shadow: -407px 0 0 400px #00fd0a;
}

.chat {
  border: solid 1px #EEE;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #d5d5d5;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #d5d5d5;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: #ececec;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom,#c52f2e 0%, #6f1313 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom,#c52f2e 0%, #6f1313 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: #ececec;
  border-bottom-left-radius: 10px;
}
